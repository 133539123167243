$primary-color: #35374B;
$secundary-color: #344955;
$third-color: #50727B;
$fourth-color: #78A083;
$primary-text: #fff;
$secundary-text: #d7d9de;

.navbar {
  height: auto;
  width: auto;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $primary-color;

  .container {
    display: flex;
    margin: 0px 26px;
    padding-right: 80px;
    align-items: center;
    justify-content: center;

    .logo{
      h1{
        height: 100%;
      }
      text-decoration: none;
      list-style: none;
      color: #fff; 
    }
    nav{
      width: 80vw;
      height: 100%;
      ul {
        display: flex;
        justify-content: center;
        li {
          list-style-type: none;
          a {
            text-decoration: none;
            list-style: none;
            padding: 2rem 2.5rem;
            color: #fff; 
          }
          a:hover {
            background-color: $secundary-color;
          }
          a.active{
            background-color: $third-color;
          }
        }
      }
    }
  }
}
.links-drawer{
  font-size:  30px;
  a{
    display: flex;
    margin: 10px 0px;
  }
}
.links-menu{
  display: none;
}

@media (max-width: 800px) {
  .links-menu{
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .links-list{
    display: none;
  }
  .navbar{
    .container{
      padding: 0;
    }
  }
}