.container-form-info{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
  height: 70vh;
  .container-info{

    h1{
      margin-bottom: 50px;
    }
    h2{
      font-size: 1.5rem;
    }
    .socials{
      font-size: 2rem;
      display: flex;
      justify-content: space-evenly;
      a{
        text-decoration: none;
        list-style: none;
        color: #fff; 
      }
    }
  }
  .container-form{
    width:45vh;
    height: 40vh;
    //background-color: blue;
    form{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width:auto;
      max-width: 400px;
      min-width: 100px;
      height: 100%;
      #name, #email{
        margin-top: .5rem;
        margin-bottom: .5rem;
        font-size: 16px;
        color: #EBF4F6;
        background-color: #35374B;
        border: 0;
        box-sizing: border-box;
        padding: 10px 20px;
      }
      textarea{
        height: 100%;
        margin-top: 1rem;
        font-size: 16px;
        color: #EBF4F6;
        background-color: #35374B;
        border: 0;
        box-sizing: border-box;
        padding: 10px 20px;
      }
      .send-button{
        width: 5rem;
        margin-top: 1rem;
        color: #78A083;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #78A083;
        float: right;
        border-radius: 4px;
      }
      .send-button:hover{
        background-color: #78A083;
        color: #fff;
      }
    }
  }
}

@media (min-width:720px){
  .container-form-info{
    height: 60vh;
  }
}