$primary-color: #2c2f30;
$primary-color: #35374B;
$secundary-color: #344955;
$third-color: #50727B;
$fourth-color: #78A083;
$primary-text: #fff;
$secundary-text: #d7d9de;

.card{
  width: 400px;
  height: 500px;
  border-style: solid;
  border-width: 5px;
  border-radius: 8px;
  border-color: $fourth-color;
  margin: 10px;
  background-color: $primary-color;

  a{
    text-decoration: none;
    list-style: none;
    color: #fff; 
  }

  .imagen{
    background-size: cover;
    width: auto;
    height: 40%;
  }

  h2{
    height: 10%;
    display: flex;
    justify-content: center;
  }
  p{
    width: 100%;
    height: 30%;
    padding-left: 10px;
    padding-right: 10px;
    color: $secundary-text;
  }
  .tec{
    display: flex;
    align-items: center;
    span{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}