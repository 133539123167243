$primary-color: #2c2f30;
$secundary-color: #1d1f21;
$primary-text: #fff;
$secundary-text: #d7d9de;
$green: #0bd685;
$purple: rgba(136, 92, 156, 0.63);

.projects-container{
  height: auto;
  padding: 60px 25px;
  margin-bottom: 10px;

  h1{
    display: flex;
    justify-content: center;
  }

  .cards-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    h2{
      background-color: 1rem;
    }
  }
  @media (max-width: 1400px){
    .cards-container{
      h2{
        
      }
    }
  }
}

.personalProjects{
  margin-bottom: 80px;
}