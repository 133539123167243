$primary-color: #35374B;
$secundary-color: #344955;
$third-color: #50727B;
$fourth-color: #78A083;
$primary-text: #fff;
$secundary-text: #d7d9de;

html{
  scroll-behavior: smooth;
}

h1{
  font-size: 2rem;
}
h2{
  font-size: 1.5rem;
}

body {
  background-color: $secundary-color;
  color: $primary-text;
}
//Large screen
@media (max-width: 1400px){
  html{
    font-size: 20px;
  }
}
//Tablet
@media (max-width: 768px){
  html{
    font-size: 18px;
  }
}
//Mobile
@media (max-width: 450px){
  html{
    font-size: 12px;
  }
}