$primary-color: #2c2f30;
$secundary-color: #1d1f21;
$primary-text: #fff;
$secundary-text: #d7d9de;
$green: #0bd685;
$purple: rgba(136, 92, 156, 0.63);
.container-banner{
  height: 50rem;
  background-image: url("../../assets/banner.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .banner-imagen{
    position: relative;
    display: flex; 
    img {
      position: absolute;
      width: 100%;
      z-index: -2;
      
    }
  }
  .banner-texto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    padding: 0px 50px;
    h1{
      font-size: 4rem;
    }
    p{
      color: $secundary-text;
      margin: 2px;
    }
  }
}
